/*
 * @Description:
 * @Author: 名字
 * @Date: 2024-03-28 14:57:30
 * @LastEditTime: 2024-03-29 16:03:09
 * @LastEditors: 名字
 */
import { applyNeedCount, archivesNeedCount } from "@/api/member/member"
const member = {
    state: {
        applyNeedCount: 0,
        archiveNeedCount: 0,
        applyNeed: false, //需确认缴费
        archiveNeed: false //需确认续费
    },
    actions: {
        //统计需确认缴费的入会申请数
        GetApplyConfirmCount({ commit }, type) {
            return new Promise((resolve, reject) => {
                // applyNeedCount().then((res) => {
                //     if (res.code === 0) {
                //         const needCount = res.data.total;
                //         commit("SET_APPLY_NEED_COUNT", needCount);
                //         commit("SET_APPLY_NEED", type ? false : (needCount > 0 ? true : false));
                //     }
                //     resolve(res);
                // }).catch((error) => {
                //     reject(error);
                // })
                commit("SET_APPLY_NEED_COUNT", 0);
                commit("SET_APPLY_NEED", false);
            });
        },

        //统计需确认续费的入会申请数
        GetArchivesConfirmCount({ commit }, type) {
            return new Promise((resolve, reject) => {
                // archivesNeedCount().then((res) => {
                //     if (res.code === 0) {
                //         const needCount = res.data.total;
                //         commit("SET_ARCHIVE_NEED_COUNT", needCount);
                //         commit("SET_ARCHIVE_NEED", type ? false : (needCount > 0 ? true : false));
                //     }
                //     resolve(res);
                // }).catch((error) => {
                //     reject(error);
                // })
                commit("SET_ARCHIVE_NEED_COUNT", 0);
                commit("SET_ARCHIVE_NEED", false);
            });
        },

    },
    mutations: {
        SET_APPLY_NEED: (state, flag) => {
            state.applyNeed = flag;
        },

        SET_APPLY_NEED_COUNT: (state, num) => {
            state.applyNeedCount = num;
        },

        SET_ARCHIVE_NEED: (state, flag) => {
            state.archiveNeed = flag;
        },

        SET_ARCHIVE_NEED_COUNT: (state, num) => {
            state.archiveNeedCount = num;
        },
    },
}
export default member
