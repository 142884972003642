/* eslint-disable no-unused-vars */
import Vue from 'vue'
import loadVue from './index.vue'
import { addClass, removeClass, getStyle } from 'element-ui/src/utils/dom'
import { PopupManager } from 'element-ui/src/utils/popup'
import afterLeave from 'element-ui/src/utils/after-leave'
import { h } from 'vue'
const Mask = Vue.extend(loadVue)

const loadDirective = {}

loadDirective.install = (Vue) => {
  if (Vue.prototype.$isServer) return

  const toggleLoading = (el, binding) => {
    const { expand } = binding.modifiers

    if (expand) {
      if (binding.value.loading) {
        Vue.nextTick(() => {
          if (binding.modifiers.fullscreen) {
            el.originalPosition = getStyle(document.body, 'position')
            el.originalOverflow = getStyle(document.body, 'overflow')
            el.maskStyle.zIndex = PopupManager.nextZIndex()

            addClass(el.mask, 'is-fullscreen')
            // eslint-disable-next-line no-use-before-define
            insertDom(document.body, el, binding)
          } else {
            removeClass(el.mask, 'is-fullscreen')

            // if (binding.value.subText) {
            //   let nodeHtml = el.mask.querySelector('.load-content')
            //   let span = el.mask.querySelector('.sub')
            //   console.log('span', span)
            //   span && nodeHtml.removeChild(span)
            // }

            if (binding.modifiers.body) {
              el.originalPosition = getStyle(document.body, 'position')[('top', 'left')].forEach((property) => {
                const scroll = property === 'top' ? 'scrollTop' : 'scrollLeft'
                el.maskStyle[property] = el.getBoundingClientRect()[property] + document.body[scroll] + document.documentElement[scroll] - parseInt(getStyle(document.body, `margin-${property}`), 10) + 'px'
              })
              ;['height', 'width'].forEach((property) => {
                el.maskStyle[property] = el.getBoundingClientRect()[property] + 'px'
              })

              // eslint-disable-next-line no-use-before-define
              insertDom(document.body, el, binding)
            } else {
              el.originalPosition = getStyle(el, 'position')
              // eslint-disable-next-line no-use-before-define
              insertDom(el, el, binding)
            }
          }
        })
      } else {
        afterLeave(
          el.instance,
          (_) => {
            if (!el.instance.hiding) return
            el.domVisible = false
            const target = binding.modifiers.fullscreen || binding.modifiers.body ? document.body : el
            removeClass(target, 'el-loading-parent--relative')
            removeClass(target, 'el-loading-parent--hidden')
            el.instance.hiding = false
          },
          300,
          true
        )
        el.instance.visible = false
        el.instance.hiding = true
      }
      return
    }

    if (binding.value) {
      Vue.nextTick(() => {
        if (binding.modifiers.fullscreen) {
          el.originalPosition = getStyle(document.body, 'position')
          el.originalOverflow = getStyle(document.body, 'overflow')
          el.maskStyle.zIndex = PopupManager.nextZIndex()

          addClass(el.mask, 'is-fullscreen')
          // eslint-disable-next-line no-use-before-define
          insertDom(document.body, el, binding)
        } else {
          removeClass(el.mask, 'is-fullscreen')

          if (binding.modifiers.body) {
            el.originalPosition = getStyle(document.body, 'position')[('top', 'left')].forEach((property) => {
              const scroll = property === 'top' ? 'scrollTop' : 'scrollLeft'
              el.maskStyle[property] = el.getBoundingClientRect()[property] + document.body[scroll] + document.documentElement[scroll] - parseInt(getStyle(document.body, `margin-${property}`), 10) + 'px'
            })
            ;['height', 'width'].forEach((property) => {
              el.maskStyle[property] = el.getBoundingClientRect()[property] + 'px'
            })

            // eslint-disable-next-line no-use-before-define
            insertDom(document.body, el, binding)
          } else {
            el.originalPosition = getStyle(el, 'position')
            // eslint-disable-next-line no-use-before-define
            insertDom(el, el, binding)
          }
        }
      })
    } else {
      afterLeave(
        el.instance,
        (_) => {
          if (!el.instance.hiding) return
          el.domVisible = false
          const target = binding.modifiers.fullscreen || binding.modifiers.body ? document.body : el
          removeClass(target, 'el-loading-parent--relative')
          removeClass(target, 'el-loading-parent--hidden')
          el.instance.hiding = false
        },
        300,
        true
      )
      el.instance.visible = false
      el.instance.hiding = true
    }
  }

  const insertDom = (parent, el, binding) => {
    if (!el.domVisible && getStyle(el, 'display') !== 'none' && getStyle(el, 'visibility') !== 'hidden') {
      Object.keys(el.maskStyle).forEach((property) => {
        el.mask.style[property] = el.maskStyle[property]
      })

      if (el.originalPosition !== 'absolute' && el.originalPosition !== 'fixed') {
        addClass(parent, 'el-loading-parent--relative')
      }
      if (binding.modifiers.fullscreen && binding.modifiers.lock) {
        addClass(parent, 'el-loading-parent--hidden')
      }
      el.domVisible = true

      // console.log('parent', el.mask)

      parent.appendChild(el.mask)

      if (binding.value.subText) {
        let nodeHtml = el.mask.querySelector('.load-content')

        let span = el.mask.querySelector('.sub')
        // console.log('span', span)
        span && nodeHtml.removeChild(span)

        const spanVNode = document.createElement('span')

        spanVNode.innerText = binding.value.subText
        spanVNode.className = 'sub'

        const { func } = binding.value
        spanVNode.onclick = func && func.bind(this)

        nodeHtml.appendChild(spanVNode)
      }

      Vue.nextTick(() => {
        if (el.instance.hiding) {
          el.instance.$emit('after-leave')
        } else {
          el.instance.visible = true
        }
      })
      el.domInserted = true
    } else if (el.domVisible && el.instance.hiding === true) {
      el.instance.visible = true
      el.instance.hiding = false
    }
  }

  Vue.directive('load', {
    bind: function (el, binding, vnode) {
      const textExr = el.getAttribute('element-loading-text')
      const spinnerExr = el.getAttribute('element-loading-spinner')
      const backgroundExr = el.getAttribute('element-loading-background')
      const customClassExr = el.getAttribute('element-loading-custom-class')
      const vm = vnode.context

      const mask = new Mask({
        el: document.createElement('div'),
        data: {
          loadText: binding.value.loadText,
          spinner: (vm && vm[spinnerExr]) || spinnerExr,
          background: binding.value.background,
          customClass: binding.value.customClass,
          fullscreen: !!binding.modifiers.fullscreen,
          size: binding.value.size
        }
      })
      el.instance = mask
      el.mask = mask.$el
      el.maskStyle = {}
      binding.value.func && binding.value.func()

      binding.value && toggleLoading(el, binding)
    },

    update: function (el, binding) {
      el.instance.setText(el.getAttribute('element-loading-text'))

      const { expand } = binding.modifiers
      if (expand) {
        toggleLoading(el, binding)

        return
      }
      if (binding.oldValue !== binding.value) {
        toggleLoading(el, binding)
      }
    },

    unbind: function (el, binding) {
      // console.log('2222')
      if (el.domInserted) {
        el.mask && el.mask.parentNode && el.mask.parentNode.removeChild(el.mask)

        if (binding.value.subText) {
          let nodeHtml = el.mask.querySelector('.load-content')
          let span = el.mask.querySelector('.sub')
          // console.log('span', span)
          span && nodeHtml.removeChild(span)
        }

        toggleLoading(el, { value: false, modifiers: binding.modifiers })
      }

      el.instance && el.instance.$destroy()
    }
  })
}

export default loadDirective
