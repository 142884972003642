/*
 * @Description: 权限管理
 * @Author: zjy
 * @Date: 2024-01-06 12:04:56
 * @LastEditTime: 2024-01-08 16:48:32
 * @LastEditors: 名字
 */

import request from '@/utils/request'

/**
 *  功能列表
 * @param {*} params
 * @returns
 */
export const rightList = (params) => request.post('/pcmanager/right/list', params)

/**
 *  创建角色信息
 * @param {*} params
 * @returns
 */
export const createRole = (params) => request.post('/pcmanager/role/create', params)


/**
 *  角色列表
 * @param {*} params
 * @returns
 */
export const roleList = (params) => request.post('/pcmanager/role/list', params)


/**
 *  修改角色信息
 * @param {*} params
 * @returns
 */
export const updateRole = (params) => request.post('/pcmanager/role/update', params)

/**
 *  角色详情
 * @param {*} params
 * @returns
 */
export const roleDetail = (params) => request.post('/pcmanager/role/detail', params)

/**
 *  删除角色
 * @param {*} params
 * @returns
 */
export const deleteRole = (params) => request.post('/pcmanager/role/delete', params)


/**
 *  获取角色的功能列表
 * @param {*} params
 * @returns
 */
export const roleRightsList = (params) => request.post('/pcmanager/role/rights', params)




/**员工管理*/
/**
 *  修改员工信息
 * @param {*} params
 * @returns
 */
export const updateStuff = (params) => request.post('/pcmanager/stuff/update', params)

/**
 *  创建员工
 * @param {*} params
 * @returns
 */
export const createStuff = (params) => request.post('/pcmanager/stuff/create', params)

/**
 *  员工列表
 * @param {*} params
 * @returns
 */
export const stuffList = (params) => request.post('/pcmanager/stuff/list', params)

/**
 *  获取员工的功能列表
 * @param {*} params
 * @returns
 */
export const stuffRightsList = (params) => request.post('/pcmanager/stuff/rights', params)

/**
 *  删除员工
 * @param {*} params
 * @returns
 */
export const stuffDetele = (params) => request.post('/pcmanager/stuff/delete', params)

/**
 *  员工详情
 * @param {*} params
 * @returns
 */
export const stuffDetail = (params) => request.post('/pcmanager/stuff/detail', params)

/**
 *  获取rsa加密公钥
 * @param {*} params
 * @returns
 */
export const getPubKey = (params) => request.get('/pcmanager/auth/keygen', params)