<!--
 * @Description: 
 * @Author: 名字
 * @Date: 2024-01-05 10:49:27
 * @LastEditTime: 2024-02-22 11:20:17
 * @LastEditors: 名字
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script> 
import store from './store'
import router from './router'
export default {  
  data() {  
    return {  
      lastTime: null, // 上次活动时间戳  
      sessionTimeout: 1000*60*60*2, // 超时时间2小时（毫秒）  
      timer: null, // 定时器  
    };  
  },  
  mounted() {  
    this.startSessionTimeout();  
    //监听鼠标点击时间，获取最后一次点击时间
    window.document.onmousedown = (event) =>{
      this.updateLastActivity();
      // console.log('重置最后一次点击的时间',this.lastTime)
    }
  },  
  beforeDestroy() {  
    this.stopSessionTimeout();  
  },  
  methods: {
  
    startSessionTimeout() {  
      this.lastTime = Date.now();  
      this.timer = setInterval(() => {  
        //判断系统无操作时间是否大于2小时
        const now = Date.now();  
        const elapsedTime = now - this.lastTime;  
        if (elapsedTime > this.sessionTimeout) {  
          this.handleSessionTimeout();  
        }  
      }, 1000);  
    },  
    stopSessionTimeout() {  
      clearInterval(this.timer);  
    },  
    //2小时没有操作，就踢下线重新登陆
    handleSessionTimeout() {
      const path = this.$route.path;
      //当前所在不是登录页
      if(path !== '/login'){
        // 踢下线逻辑，显示警告或重定向到登录页面  
        alert('您的会话已超时，请重新登录。');
        this.updateLastActivity();
        // 重定向到登录页面或执行其他操作  
        store.dispatch('LogOut').then(() => router.push({ path: '/login' }))
      }
    },  
    updateLastActivity() {  
      // 每次用户与页面交互时调用此方法  
      this.lastTime = Date.now();  
    },  
  },  
};  
</script>