/*
 * @Description: 权限管理
 * @Author: 名字
 * @Date: 2024-01-28 12:38:33
 * @LastEditTime: 2024-02-01 13:37:35
 * @LastEditors: 名字
 */
const premission = {
    namespaced: true,
    state: {
        roleList: [], //角色列表
        rightList: [] //功能列表（权限列表）
    },
    actions: {
        // GetRoleList({ state, commit }, roleList) {
        //     commit('SET_ROLELIST', roleList)
        // }
        // GetRoleList: ({ commit }, roleList) => commit('SET_ROLELIST', roleList)
    },

    mutations: {
        //添加角色
        SET_ROLELIST: (state, roleList) => {
            state.roleList = roleList
        },
        //功能列表
        SET_RIGHTLIST: (state, rightList) => {
            state.rightList = rightList
        }
    }
}
export default premission
