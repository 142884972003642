import {
    setRefreshToken,
    removeToken,
    removeRefreshToken,
} from "@/utils/common-def"
import { Message } from "element-ui"
import { setStore, getStore } from "@/utils/store"
import { login, stuffMe, refreshToken, userList } from "@/api/login"
import { stuffList, rightList } from "@/api/premission/premission"
import { archivesList } from "@/api/member/member"
import { convertToTimestamp, getRightTreeData } from "@/utils/util"

const user = {
    state: {
        userInfo: getStore({ name: "userInfo" }) || {},
        token: getStore({ name: "token" }) || "",
        refreshToken: getStore({ name: "refreshToken" }) || "",
        userList: [], //用户列表
        liaisonList: [], //联系人列表
        memberList: [], //会员列表
        companyList: [], //公司列表
        userPremission: [], //用户权限
        premission_routes: [], //路由
        applyNeed: false, //需确认缴费
        archiveNeed: false //需确认续费
    },
    actions: {
        //根据手机号登录
        LoginByPhone({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                const vo = {
                    name: userInfo.name,
                    password: userInfo.password,
                };
                let config = {
                    label: 'login'
                }
                login(vo, config)
                    .then((res) => {
                        if (res.code === 0 && res.data) {
                            const data = res.data;
                            //日期格式化
                            // const token_expire = convertToTimestamp(data.token_expire_unix)
                            // const token_refresh = convertToTimestamp(data.token_refresh_unix);
                            // console.log("expire", token_expire, token_refresh)
                            commit("SET_TOKEN", data.token);
                        } else {
                            Message({
                                message: '账号或密码错误',
                                type: "error",
                            });
                        }
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },

        //获取用户列表
        GetUserList({ commit }) {
            return new Promise((resolve, reject) => {
                const vo = {
                    province: "",
                    city: "",
                    keyword: "",
                    page: 1,
                    size: 100,
                };
                userList(vo)
                    .then((res) => {
                        let list = res.data.data;
                        let liaisonList = []
                        if (res.code === 0 && list) {
                            list.forEach((item) => {
                                const company_name = item.company_name ? "-" + item.company_name : "";
                                const title = item.title ? "-" + item.title : "";
                                const value = item.name + company_name + title;
                                liaisonList.push({
                                    id: item.user_id,
                                    value: value,
                                    phone_number: item.phone_number,
                                    company_id: item.company_id,
                                });
                            })
                        }
                        commit("SET_USER_lIST", list);
                        commit("SET_LIAISON_lIST", liaisonList)
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
            });
        },

        //获取用户信息
        GetUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                stuffMe()
                    .then((res) => {
                        const data = res.data;
                        commit("SET_USER_INFO", data)
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    })
            });
        },

        //获取权限信息
        GetRightInfo({ commit }, rights) {
            return new Promise((resolve, reject) => {
                rightList()
                    .then((res) => {
                        if (res.code === 0 && res.data) {
                            const data = res.data
                            //data根据导航栏展示排序
                            const menuItem = ['/index', '/permission', '/product', '/member', '/message','/transaction'];
                            let rightArr = [];
                            menuItem.forEach((item) => {
                                data && data.forEach((v) => {
                                    if (v.path === item) {
                                        rightArr.push(v)
                                    }
                                })
                            })
                            // console.log('rightList', rightArr, rights)
                            //存储权限列表到vuex
                            commit("premission/SET_RIGHTLIST", rightArr)
                            const user_premission_list = getRightTreeData(rightArr, rights)
                            commit("SET_USER_PREMISSION", user_premission_list)
                        }
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
            });
        },

        GenerateRoutes({ commit }, routes) {
            return new Promise((resolve, reject) => {
                commit('SET_PREMISSION_ROUTES', routes);
                resolve();
            })
        },

        //获取员工列表
        GetStuffList({ commit }) {
            return new Promise((resolve, reject) => {
                const vo = {
                    name: "",
                    page: 1,
                    size: 100,
                };
                stuffList(vo)
                    .then((res) => {
                        const data = res.data;
                        // const liaisonList = []
                        if (res.code === 0 && data) {
                            // data.forEach(item => {
                            //   liaisonList.push({
                            //     id:item.user_id,
                            //     value:item.name,
                            //     phone_number: item.phone_number,
                            //     company_id:item.company_id
                            //   })
                            // })
                        }
                        commit("SET_USER_lIST", data);
                        commit("SET_LIAISON_lIST", liaisonList)
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
            });
        },

        //获取会员单位
        GetMemberList({ commit }) {
            return new Promise((resolve, reject) => {
                const vo = {
                    //过滤条件
                    filter: {},
                    sort: [],
                };
                archivesList(vo)
                    .then((res) => {
                        let data = res.data;
                        if (res.code === 0 && data) {
                            const company_list = data.company_list;
                            const dataList = []
                            company_list.forEach((item) => {
                                const member_type = item.member_type;
                                const label =
                                    member_type === 0
                                        ? "非会员单位"
                                        : member_type === 1
                                            ? "生态企业"
                                            : member_type === 2
                                                ? "会员单位"
                                                : member_type === 3
                                                    ? "理事单位"
                                                    : member_type === 4
                                                        ? "副会长单位"
                                                        : "会长单位"
                                //   无为科技有限公司 - 副会长单位
                                const value = item.company_name + "-" + label;
                                dataList.push({
                                    id: item.company_id,
                                    value: value,
                                    name: item.company_name,
                                });
                            })
                            commit("SET_MEMBER_lIST", data.company_list);
                            commit("SET_COMPANY_lIST", dataList) //查询使用的企业
                        }
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
            });
        },

        //刷新token
        refreshToken({ state, commit }, userInfo) {
            window.console.log("handle refresh token");
            return new Promise((resolve, reject) => {
                refreshToken({})
                    .then((res) => {
                        const data = res.data;
                        commit("SET_TOKEN", data.token)
                        // 获取用户信息
                        dispatch("GetUserInfo");
                        resolve()
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        // 登出
        LogOut({ commit }) {
            return new Promise((resolve, reject) => {
                //清楚缓存信息
                commit("SET_TOKEN", "");
                commit("SET_USER_INFO", "")
                commit("SET_USER_lIST", "");
                commit("SET_LIAISON_lIST", "");
                commit("SET_MEMBER_lIST", "");
                commit("SET_COMPANY_lIST", "");
                commit("SET_USER_PREMISSION", "");
                commit("SET_PREMISSION_ROUTES", "");
                removeToken()
                removeRefreshToken()
                resolve()
            });
        }
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            // setToken(token)
            state.token = token;
            setStore({ name: "token", content: state.token })
        },
        SET_REFRESH_TOKEN: (state, refreshToken) => {
            setRefreshToken(refreshToken);
            state.refreshToken = refreshToken
            setStore({ name: "refreshToken", content: state.refreshToken });
        },
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo;
            setStore({ name: "userInfo", content: state.userInfo })
        },

        SET_USER_lIST: (state, userList) => {
            state.userList = userList;
        },

        SET_LIAISON_lIST: (state, liaisonList) => {
            state.liaisonList = liaisonList;
        },

        SET_MEMBER_lIST: (state, memberList) => {
            state.memberList = memberList;
        },

        SET_COMPANY_lIST: (state, companyList) => {
            state.companyList = companyList;
        },

        SET_USER_PREMISSION: (state, rightList) => {
            state.userPremission = rightList;
        },

        SET_PREMISSION_ROUTES: (state, list) => {
            state.premission_routes = list;
        },

        SET_APPLY_NEED: (state, flag) => {
            state.applyNeed = flag;
        },

        SET_ARCHIVE_NEED: (state, flag) => {
            state.archiveNeed = flag;
        },
    },
}
export default user
