import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store'
// import IndexView from "../views/index/index.vue";
import Login from "../views/login/index.vue";
import PaySucc from "../views/PaySucc/index.vue";

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  // 这里是一些静态路由
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/pay_succ',
    name: 'PaySucc',
    component: PaySucc
  },
  // {
  //   path: '*',
  //   redirect: '/404'
  // }
];

const router = new VueRouter({
  routes,
});

let routerArray = []; //存储已有路由地址
// 动态生成多级路由的方法
function generateRoutes(data, parentPath) {
  const routes = [];
  data.forEach(item => {
    if (!item.type) {
      item.type = 0
    }
    const route = {
      path: item.path,
      // component: () => import('../views/index/index.vue'),
      component(resolve) {
        //../views/product/index.vue
        let component = 'views' + item.component
        // 功能节点
        if (item.type === 1) {
          //../views/product/components/banner/banner-form.vue
          component = 'views' + parentPath + '/components' + item.component
        }
        require([`../${component}.vue`], resolve)
        return
      },
      meta: {
        title: item.name
      },
      children: []
    };
    const hasChild = item.children && item.children.length > 0
    let hasComponent = false
    let childList = item.children && item.children[0] && item.children[0].children
    if (hasChild && childList) {
      childList.forEach((v) => {
        if (v.component && v.component.length > 0) {
          hasComponent = true
        }
      })
    }
    if (item.type === 0 || item.type === 1) {
      if (hasComponent && childList) {
        item.children.forEach(citem => {
          route.children = [...route.children, ...generateRoutes(citem.children, item.path)];
        })
      }
      //该路由下还有子节点
      if (hasChild && hasComponent) {
        route.redirect = route.children && route.children[0].path;
      }
      routerArray.push(route.path);
      routerArray = Array.from(new Set(routerArray));
      routes.push(route);
    }
  });
  // console.log('routes', routes)
  return routes;
}

//加载loading
// function showLoading() {
//   Vue.prototype.$loading({
//     lock: true,
//     text: "",
//     spinner: "el-icon-loading"
//   })
// }

// //关闭loading方法
// function endLoading() {
//   Vue.prototype.$loading().close();
// }

// 导航守卫
router.beforeEach(async (to, from, next) => {
  // showLoading() //开始访问接口时调用loading
  to.path !== '/login' && store.commit('UPDATE_LOADIN', true);
  //如果去登录页进行跳转
  if (to.path === '/login' || to.path === '/pay_succ') {
    // endLoading() //接口访问结束，关闭loading
    next()
  } else {
    let routeData = [
      {
        path: '/',  // 程序启动默认路由
        component: (resolve) => require(['@/components/common/basic-container.vue'], resolve),
        meta: { title: '运营门户' },
        redirect: '/index',  // 重定向到首页
        children: []
      }
    ];
    //查询有没有token
    if (store.getters.token) {
      //路由
      const premission_routes = store.getters.premission_routes;
      if (premission_routes.length > 0) {
        let hasPath = false;
        //导航菜单，第一层级
        // console.log(routerArray)
        //路由是否含有当前访问路径
        if (routerArray && routerArray.indexOf(to.path) !== -1) {
          hasPath = true
          //兼容后端返回数据排序和产品需求展示不一致，会员管理默认展示个人档案列表页
          let defaultPath = to.path;
          if (to.redirectedFrom === '/member' && routerArray.indexOf('/member/person/archive') !== -1) {
            defaultPath = '/member/person/archive';
            next({
              path: defaultPath
            })
          }
        }
        if (!hasPath) { //当前访问的菜单不存在，则重定向菜单默认第一个菜单
          const defaultPath = premission_routes[0].redirect || premission_routes[0].path;
          next({
            path: defaultPath
          })
        } else {
          next()
        }
      } else {
        try {
          // 若未处理用户的路由访问权限，则进行如下处理
          //清空缓存的路由地址
          routerArray = [];
          // 动态添加路由
          const rightData = store.getters.userPremission
          if (rightData.length > 0) {
            const routesList = await generateRoutes(rightData);
            routeData[0].children = routesList;
            // routeData[0].redirect = routesList[0].path; //重置默认重定向地址
            // 假设你有一个表示多级路由结构的数据
            routeData.forEach((item) => {
              // console.log('加路由')
              router.addRoute(item);
              next({ ...to, replace: true }); //继续路由导航
            })
            //把当前系统菜单的路由缓存在Vuex
            await store.dispatch('GenerateRoutes', routesList)
            router.options.isAddAsyncMenuData = true;
          } else {
            //获取当前系统的菜单权限
            const userInfo = store.getters.userInfo;
            if (userInfo.rights) {
              await store.dispatch('GetRightInfo', userInfo.rights)
            } else {
              next(error)
            }
          }
          next({ ...to, replace: true })
        } catch (error) {
          // 抛出错误
          if (error) {
            // endLoading() //接口访问结束，关闭loading
            console.error('Error:' + error);
            // Message.error(error)
          }
        }
      }
    } else {
      // endLoading() //接口访问结束，关闭loading
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }
})

// 这里为了让效果明显一些加了延时
router.afterEach((to, from) => {
  setTimeout(function () {
    // endLoading() //接口访问结束，关闭loading
    // console.log(to.path)
    store.commit('UPDATE_LOADIN', false);
  }, 500);
});

export default router;
