/*
 * @Description: 
 * @Author: 名字
 * @Date: 2024-01-06 12:34:29
 * @LastEditTime: 2024-04-17 19:58:05
 * @LastEditors: 名字
 */
const getters = {
    token: (state) => state.user.token,
    website: (state) => state.common.website,
    userInfo: (state) => state.user.userInfo,
    roleList: (state) => state.premission.roleList,
    rightList: (state) => state.premission.rightList,
    userList: (state) => state.user.userList,
    liaisonList: (state) => state.user.liaisonList,
    memberList: (state) => state.user.memberList,
    companyList: (state) => state.user.companyList,
    userPremission: (state) => state.user.userPremission,
    premission_routes: (state) => state.user.premission_routes,
    applyNeed: (state) => state.member.applyNeed,
    archiveNeed: (state) => state.member.archiveNeed,
    applyNeedCount: (state) => state.member.applyNeedCount,
    archiveNeedCount: (state) => state.member.archiveNeedCount,
    isLoading: (state) => state.common.isLoading,
}
export default getters
