/*
 * @Description: 
 * @Author: 名字
 * @Date: 2024-01-06 12:24:16
 * @LastEditTime: 2024-01-30 09:58:16
 * @LastEditors: 名字
 */
/**
 * 全局配置文件
 */
export default {
    title: 'mis',
    logo: 'S',
    key: 'mis', //配置主键,目前用于存储
    indexTitle: '深圳市车联网行业协会',
    tokenTime: 3000,
    //http的status默认放行列表
    statusWhiteList: [],
}
