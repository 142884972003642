/*
 * @Description: 
 * @Author: 名字
 * @Date: 2024-01-15 11:43:54
 * @LastEditTime: 2024-02-20 14:35:14
 * @LastEditors: 名字
 */
/**
 *
 * @param {*} func 函数
 * @param {*} delay 时间
 * @returns
 */
export const debounce = (func, delay) => {
  let timer = null
  return function () {
    if (timer) clearTimeout(timer)
    timer = null
    let self = this
    let args = arguments
    timer = setTimeout(() => {
      func.apply(self, args)
    }, delay)
  }
}

export const getObjType = (obj) => {
  let toString = Object.prototype.toString
  let map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}


/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  let type = getObjType(data)
  let obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    //不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (let key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}


/**
* 日期格式化
*/
export function dateFormat(date, format) {
  date = new Date(date * 1000); // 将秒级时间戳转换为毫秒级时间戳  
  format = format || 'yyyy-MM-dd hh:mm:ss'
  if (date && date !== 'Invalid Date') {
    let o = {
      'M+': date.getMonth() + 1, //month
      'd+': date.getDate(), //day
      'h+': date.getHours(), //hour
      'm+': date.getMinutes(), //minute
      's+': date.getSeconds(), //second
      'q+': Math.floor((date.getMonth() + 3) / 3), //quarter
      S: date.getMilliseconds() //millisecond
    }
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (let k in o) if (new RegExp('(' + k + ')').test(format)) format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    return format
  }
  return ''
}


/**
 * 日期转为秒级时间戳（10位）
 */
export function convertToTimestamp(val) {
  const date = new Date(val);
  const timestamp = Math.floor(date.getTime() / 1000); // 将毫秒转换为秒并取整
  return timestamp
}

/**
 * 当前时间戳
 */
export function dateNow() {
  return dateFormat(new Date(), "yyyyMMddhhmmss");
}


//对金额进行处理
export function upMoney(contractMoney) {
  contractMoney = contractMoney / 10000;
  return contractMoney.toFixed(2)
}


/**
      * 递归计算选中的节点
      */
export function getRightTreeData(nodes, selectedKeys) {
  const ret = []
  nodes.forEach(node => {
    const newNode = { ...node }
    delete newNode.children
    node.children && (newNode.children = getRightTreeData(node.children, selectedKeys))
    if (selectedKeys.includes(newNode.right_id) || newNode.children && newNode.children.length) {
      ret.push(newNode)
    }
  })
  return ret
}

//获取菜单权限
import store from '@/store/'
export function getMenuItem(list, array) {
  list.forEach((item) => {
    array.forEach((v) => {
      if (!v.type) {
        v.type = 0
      }
      if (v.type === 0 || v.type === 1) {
        if (v.path === item.path) {
          item.isShow = true
        }
      }
      if (v.children && v.children.length > 0) {
        getMenuItem(list, v.children)
      }
    })
    if (item.children && item.children.length > 0) {
      const userPremission = store.getters.userPremission
      getMenuItem(item.children, userPremission)
    }
  })
  return list
}

// 去掉html字符串中的所有标签元素
export function delHtmlTag (str) {
  return str.replace(/<[^>]+>|&nbsp;/g, '')
}