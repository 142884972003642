/*
 * @Description:
 * @Author: 名字
 * @Date: 2024-01-05 10:49:27
 * @LastEditTime: 2024-01-30 09:41:46
 * @LastEditors: 名字
 */
// export const ACCESS_TOKEN = "xxx_tk";
import Cookies from "js-cookie";
const TokenKey = "mis-token";
const RefreshTokenKey = "mis-refresh-token";
export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey);
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey);
}

export const List2Objects = function (lists, keyName) {
  const tmpObjects = {};
  lists.forEach((item) => {
    const keyValue = item[keyName];
    tmpObjects[keyValue] = item;
  });

  return tmpObjects;
};

export const ENUM_ORDER_STATUS = {
  noPay: 0,
  paySucc: 1,
  payCancel: 2,
  payRefundAll: 3,
  payRefundSome: 4,
};
export const ORDER_STATUS_LIST = [
  // {
  //   value: ENUM_ORDER_STATUS.noPay,
  //   label: "待支付",
  // },
  {
    value: ENUM_ORDER_STATUS.paySucc,
    label: "已支付",
  },
  // {
  //   value: ENUM_ORDER_STATUS.payCancel,
  //   label: "已作废",
  // },
  {
    value: ENUM_ORDER_STATUS.payRefundAll,
    label: "全部退款",
  },
  {
    value: ENUM_ORDER_STATUS.payRefundSome,
    label: "部分退款",
  },
];
export const ORDER_STATUS_OBJECT = List2Objects(ORDER_STATUS_LIST, "value");

export const PAY_TYPE_LIST = [
  {
    value: 0,
    label: "资源发布次数",
  },
  {
    value: 2,
    label: "会员单位会费",
  },
  {
    value: 3,
    label: "理事会费",
  },
  {
    value: 4,
    label: "副会长会费",
  },
  {
    value: 5,
    label: "会长会费",
  },
];
export const PAY_TYPE_OBJECT = List2Objects(PAY_TYPE_LIST, "value");

export const PAY_CHANNEL_LIST = [
  {
    value: "WXPAY",
    label: "微信支付",
  },
  {
    value: "B2BBANK",
    label: "企业网银支付",
  },
  {
    value: "B2BTRANSFER",
    label: "转账支付",
  },
];
export const PAY_CHANNEL_OBJECT = List2Objects(PAY_CHANNEL_LIST, "value");

export const ENUM_REFUND_STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  PROCESSING: "PROCESSING",
};

export const ENUM_ORDER_TYPE = {
  member: 1,
  need: 2,
};
export const ORDER_TYPE_LIST = [
  {
    value: ENUM_ORDER_TYPE.member,
    label: "会费缴纳",
  },
  {
    value: ENUM_ORDER_TYPE.need,
    label: "供需条数购买",
  },
];
