/*
 * @Description: 
 * @Author: 名字
 * @Date: 2024-01-06 12:38:33
 * @LastEditTime: 2024-04-17 19:56:29
 * @LastEditors: 名字
 */
// import { setStore, getStore, removeStore } from '@/utils/store'

const common = {
    state: {
        isLoading: false,
    },
    mutations: {
        UPDATE_LOADIN: (state, flag) => {
            state.isLoading = flag
        },
    }
}
export default common
