/*
 * @Author: zjy
 * @Date: 2024-01-19 14:34:04
 * @LastEditors: zjy
 * @LastEditTime: 2024-01-19 11:46:42
 * @Description: file content
 * @FilePath: src\directives\index.js
 */
import Vue from 'vue'
import loadMore from './loadMore.js' // 引入指令

const directives = {
  loadMore
}

Object.keys(directives).forEach((name) => Vue.directive(name, directives[name]))
