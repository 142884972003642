// import request from '@/api/axios'

import request from '@/utils/request'
import website from '@/utils/config-website'

/**
 *  会员单位列表
 * @param {*} params
 * @returns
 */
export const login = (params, config) => request.post('/pcmanager/auth/login', params, config)


/**
 *  我的信息详情
 * @param {*} params
 * @returns
 */
export const stuffMe = (params) => request.get('/pcmanager/stuff/me', params)

/**
 *  人员列表
 * @param {*} params
 * @returns
 */
export const userList = (params) => request.post('/pcmanager/user/list', params)


/**
 *  刷新登录token接口
 * @param {*} params
 * @returns
 */
export const refreshToken = (params) => request.get('/wxxcxclient/auth/refresh-token', params)