<template>
  <div class="mis-layout">
    <div class="login" :style="{ 'background-image': 'url(' + imgUrl + ')' }">
      <div class="scoll-box">
        <div class="login-form">
          <div class="login-form__title">
            <span>{{ this.title }}</span>
          </div>
          <div class="login-title"><el-divider>账号登录</el-divider></div>
          <el-form
            :model="form"
            :rules="rules"
            class="login-form__content"
            ref="loginForm"
          >
            <el-form-item prop="name" :show-message="false">
              <el-input
                prefix-icon="el-icon-user"
                v-model="form.name"
                placeholder="请输入账号"
                @focus="clearError('name')"
                @blur="blur"
                :class="activeInput === 'name' ? 'is-active' : ''"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" :show-message="false">
              <el-input
                :type="type"
                prefix-icon="el-icon-lock"
                v-model="form.password"
                placeholder="请输入密码"
                @focus="clearError('password')"
                @blur="blur"
                :class="activeInput === 'password' ? 'is-active' : ''"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="agree">
              <div class="error-msg" slot="error">{{ errMsg }}</div>
              <!-- <div class="forget_password">
                忘记密码
            </div> -->
            </el-form-item>
            <el-form-item>
              <div class="login-form__submit">
                <el-button type="primary" @click="handleLogin">登录</el-button>
              </div>
            </el-form-item>
          </el-form>
          <el-divider></el-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPubKey } from "@/api/premission/premission"
import JSEncrypt from "jsencrypt"
export default {
  mounted() {},
  data() {
    let validatePass = (rule, value, callback) => {
      if (!value) {
        this.errMsg = "你还没有输入密码！"
        this.activeInput = "password"
        this.clearErr = this.clearErr.filter((item) => item !== "password")
        callback(new Error("你还没有输入密码！"))
        return;
      }
      callback()
    };
    let validateAccount = (rule, value, callback) => {
      if (!value) {
        this.errMsg = "你还没有输入账号！"
        this.activeInput = "name"
        this.clearErr = this.clearErr.filter((item) => item !== "name")
        callback(new Error("你还没有输入账号！"))
        return;
      }
      callback()
    };
    return {
      imgUrl: require("/public/img/login_bg.png"),
      isPassword: false,
      type: "password",
      title: "运营门户登录",
      form: {
        //用户名/手机号
        name: "",
        //密码
        password: ""
      },
      rules: {
        name: [{ validator: validateAccount, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }]
      },
      clearErr: ["agree"], // 移除校验信息的数组
      errMsg: "",
      codeErrMsg: "",
      activeInput: "",
      passwordRsa: ""
    }
  },

  methods: {
    // 显示密码
    openEyes() {
      // console.log('open')
      this.isPassword = !this.isPassword
      if (this.isPassword) {
        this.type = "text"
        return;
      }
      this.type = "password"
    },
    //提交登录
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          //获取rsa加密公钥
          getPubKey()
            .then((res) => {
              // 创建 JSEncrypt 实例
              const encryptor = new JSEncrypt()
              const pubKey = res.data
              encryptor.setPublicKey(pubKey)
              // 加密用户信息
              this.passwordRsa = encryptor.encrypt(this.form.password)
            })
            .then(() => {
              // const loading = this.$loading({
              //   lock: true,
              //   text: "登录中,请稍后。。。",
              //   spinner: "el-icon-loading"
              // })
              let param = {
                name: this.form.name,
                password: this.passwordRsa
              }
              this.$store
                .dispatch("LoginByPhone", param)
                .then((res) => {
                  if (res.code === 0) {
                    //登录成功跳转
                    setTimeout(() => {
                      console.log('登录成功')
                      //延时0.5s跳转主页，用于主页获取localStorage中用户信息争取时间
                      this.$router.push({ path: "/" })
                    }, 300)
                    //获取个人信息
                    this.$store.dispatch("GetUserInfo").then((res)=>{
                      //获取当前用户权限列表
                      // const rights = res.rights;
                      // this.$store.dispatch("GetRightInfo",rights);
                      // loading.close();
                    })
                  }else{
                    this.errMsg = "账号或密码错误";
                    // loading.close();
                  }
                })
                .catch((err) => {
                  // loading.close()
                });
            })
            .catch((err) => {
              console.log(err)
            });
        } else {
          if (!this.form.password) this.errMsg = "你还没有输入密码"
          if (!this.form.name) this.errMsg = "你还没有输入账号"
          if (!this.form.name && !this.form.password) {
            this.errMsg = "请输入用户账号和密码"
            this.activeInput = "username"
          }
        }
      })
    },
    // 获取焦点清除表单校验
    clearError(prop) {
      this.codeErrMsg = ""
      this.activeInput = prop
      this.errMsg = ""
      this.clearErr.push(prop)
      this.$refs.loginForm.clearValidate(this.clearErr)
      return;
    },
    // 失去焦点时不做判空
    blur() {
      this.activeInput = ""
    },
  },
  created() {}
}
</script>

<style lang="scss" scoped>
.mis-layout {
  background: #f0f2f5;
  // padding-top: 20px;
}
.login {
  width: 100vw;
  height: 100vh;
  // overflow-y: scroll;
  background-size: cover;

  .scoll-box {
    position: relative;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 720px;
    margin: auto;
    .error-msg {
      height: 20px;
      margin-bottom: 8px;
      color: #f56c6c;
      font-size: 13px;
    }

    .logo {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 95px;
      // padding: 33px 0 0 26px;
      padding-left: 26px;
      font-weight: 500;
      font-size: 20px;
      font-family: "Noto Sans SC";
      font-style: normal;
      line-height: 95px;

      &-title {
        padding-left: 12px;
      }
    }

    ::v-deep .el-form {
      &-item {
        margin-bottom: 18px;
      }

      .is-active .el-input__inner {
        border-color: #07f !important;
        border-radius: 4px;
        box-shadow: 0 0 4px rgb(0 92 219 / 28%) !important;
      }

      .el-form-item.is-error .el-input__inner {
        border-color: #f56c6c;
      }

      .el-input {
        &__inner {
          box-sizing: border-box;
        }

        &__inner:hover {
          border-color: #07f !important;
        }
      }
    }

    .special-form {
      margin-top: 20px;
    }

    .login-form {
      width: 400px;
      height: 430px;
      padding: 20px 30px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 5px;

      &__title {
        height: 80px;
        line-height: 80px;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 30px;
        text-align: center;
      }

      .login-title {
        display: flex;
        height: 55px;
      }

      &__tip {
        margin-top: 20px;
        color: #909399;
        font-weight: 500;
        font-size: 16px;
        font-style: normal;

        span:nth-child(2) {
          padding-left: 9px;
          color: #303133;
        }
      }

      &__content {
        width: 100%;
        // padding-top: 60px;
        font-size: 16px;

        &-icon {
          font-size: 12px;
        }

        ::v-deep .el-input {
          .el-input__inner::placeholder {
            color: #c0c4cc;
            font-size: 14px;
          }

          &__inner {
            padding-left: 40px;
            color: #606266;
            font-size: 14px;
          }

          &__prefix {
            top: 1px !important;
            left: 13px !important;
          }

          &__suffix {
            padding-right: 5px;
            line-height: 40px;
          }
        }

        .forget_password {
          text-align: right;
          color: #909299;
          cursor: pointer;
        }
      }

      &__submit {
        padding-top: 0px;
        ::v-deep .el-button {
          background-color: #3083ff;
          border-color: #3083ff;
          width: 100%;
        }
      }
    }
  }

  .version-info {
    position: absolute;
    bottom: 6%;
    left: 13%;
    height: 18px;
    padding-top: 45.5px;
    color: #606266;
    font-size: 12px;
    user-select: none;
    pointer-events: none;
  }
}
</style>
