<!--
 * @Description: 
 * @Author: 名字
 * @Date: 2024-01-04 17:22:41
 * @LastEditTime: 2024-04-17 20:20:31
 * @LastEditors: 名字
-->
<template>
  <div class="mis-container" v-load.expand="{
                loading: isLoading,
                loadText: '',
                size: 48
              }">
    <div class="mis-header">
      <div class="hearder-left">
        <div class="logo">
          <img class="logo-icon" src="/img/logo.png" alt="Logo" />
          <div class="logo-title">
            <span class="title">{{ title }}</span
            ><span class="subtitle">{{ subtitle }}</span>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="hearder-title">运营门户</div>
        </div>
      </div>
      <div class="hearder-right">
        <div class="top-menu">
          <el-menu class="el-menu-demo" mode="horizontal">
            <el-menu-item class="is-active">
              <span slot="title">登录</span>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <user-login />
  </div>
</template>
<script>
import userLogin from "./userLogin.vue"
import { mapGetters } from "vuex"
export default {
  components: {
    userLogin
  },
  computed: {
    ...mapGetters(['isLoading'])
  },
  data() {
    return {
      title: "深圳市车联网行业协会",
      subtitle: "Shenzhen V2X Industry Association"
    }
  },
  watch: {},
  created() {},
  mounted() {},

  props: [],
  methods: {}
}
</script>

<style lang="scss" scoped>
.mis-header {
  height: 60px;
  border-bottom: 1px solid #e7eaf0;
  .hearder-left {
    float: left;
    width: 380px;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      margin-left: 20px;

      .el-divider--vertical {
        height: 2.2em;
      }

      .logo-icon {
        width: 50px;
        height: 50px;
      }
    }
    .logo-title {
      display: inline-block;
      margin-left: 10px;
      .title {
        display: block;
        font-size: 18px;
        font-weight: 500;
      }
      .subtitle {
        display: block;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .hearder-title {
      display: inline-block;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .hearder-right {
    float: right;
    margin-right: 20px;
    .top-menu {
      .is-active {
        color: #0077ff;
        border-bottom: 2px solid #0077ff !important;
      }
    }
  }
}
.mis-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  ::v-deep .loading-prev {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
  }
}

</style>
