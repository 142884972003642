<template>
  <transition name="el-loading-fade" @after-leave="handleAfterLeave">
    <div class="loading-prev" :class="[customClass, { 'is-fullscreen': fullscreen }]" v-show="visible" :style="{ backgroundColor: background || '' }">
      <div div class="loading-prev-box">
        <div class="loading-prev-box-warp" :style="{ width: size + 'px', height: size + 'px' }">
          <div class="loading-prev-box-item"></div>
          <div class="loading-prev-box-item"></div>
          <div class="loading-prev-box-item"></div>
          <div class="loading-prev-box-item"></div>
          <div class="loading-prev-box-item"></div>
          <div class="loading-prev-box-item"></div>
          <div class="loading-prev-box-item"></div>
          <div class="loading-prev-box-item"></div>
          <div class="loading-prev-box-item"></div>
        </div>
        <div class="load-content">
          <div class="load-text" v-if="loadText">{{ loadText }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'load',
  data() {
    return {
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: '',
      size: 80,
      loadingText: ''
    }
  },
  methods: {
    handleAfterLeave() {
      this.$emit('after-leave')
    },
    setText(text) {
      this.text = text
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-prev {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.loading-prev .loading-prev-box {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-flow: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.loading-prev .loading-prev-box-warp {
  width: 80px;
  height: 80px;
}

.loading-prev .loading-prev-box-warp .loading-prev-box-item {
  float: left;
  width: calc(100% / 3);
  height: calc(100% / 3);
  background: #07f;
  border-radius: 1px;
  animation: loading-prev-animation 1.2s infinite ease;
}

.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(7) {
  animation-delay: 0s;
}

.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(4),
.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(8) {
  animation-delay: 0.1s;
}

.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(1),
.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(5),
.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(9) {
  animation-delay: 0.2s;
}

.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(2),
.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(6) {
  animation-delay: 0.3s;
}

.loading-prev .loading-prev-box-warp .loading-prev-box-item:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-prev-animation {
  0%,
  70%,
  100% {
    transform: scale3d(1, 1, 1);
  }

  35% {
    transform: scale3d(0, 0, 1);
  }
}

.load-content {
  display: flex;
  margin-top: 10px;

  .load-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
}

::v-deep .sub {
  color: #07f;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
</style>
