/*
 * @Description: 会员管理-会员单位
 * @Author: zjy
 * @Date: 2024-01-06 12:04:56
 * @LastEditTime: 2024-03-12 14:28:06
 * @LastEditors: 名字
 */

import request from '@/utils/request'


/**
 *  入会申请列表
 * @param {*} params
 * @returns
 */
export const getApplyList = (params) => request.post('/pcmanager/member/apply/list', params)

/**
 *  入会申请详情
 * @param {*} params
 * @returns
 */
export const applyListDetail = (params) => request.post('/pcmanager/member/apply/detail', params)

/**
 *  入会申请审核通过
 * @param {*} params
 * @returns
 */
export const applyApprove = (params) => request.post('/pcmanager/member/apply/approve', params)

/**
 *  入会申请审核驳回
 * @param {*} params
 * @returns
 */
export const applyReject = (params) => request.post('/pcmanager/member/apply/reject', params)

/**
 *  入会申请生效
 * 1、当申请记录的审核状态为审核通过，并且缴费状态为未缴费时，显示该接口对应的按钮。
 * 2、该按钮的作用为当用户申请了入驻/升级会员单位，但是通过对公转账或者现金方式付费，运营人员确认之后在入会申请列表将该申请所对应的会员权益生效。
 * @param {*} params
 * @returns
 */
export const applyEffect = (params) => request.post('/pcmanager/member/apply/effect', params)


/**
 *  会员单位列表
 * @param {*} params
 * @returns
 */
export const archivesList = (params) => request.post('/pcmanager/member/archives/list', params)

/**
 *  会员单位详情
 * @param {*} params
 * @returns
 */
export const archivesDetail = (params) => request.post('/pcmanager/member/archives/detail', params)

/**
 *  会员单位新增
 * @param {*} params
 * @returns
 */
export const createArchives = (params) => request.post('/pcmanager/member/archives/create', params)

/**
 *  会员单位修改
 * @param {*} params
 * @returns
 */
export const updateArchives = (params) => request.post('/pcmanager/member/archives/update', params)


/**
 *  统计需确认缴费的入会申请数
 * @param {*} params
 * @returns
 */
export const applyNeedCount = (params) => request.post('/pcmanager/member/apply/needconfirm/count', params)

/**
 *  统计需确认缴费的会员档案数
 * @param {*} params
 * @returns
 */
export const archivesNeedCount = (params) => request.post('/pcmanager/member/archives/needconfirm/count', params)

/**
 *  需确认缴费/续费
 * @param {*} params
 * @returns
 */
export const orderMark = (params) => request.post('/pcmanager/order/mark', params)
