/*
 * @Author: zjy
 * @Date: 2024-01-15 14:34:36
 * @LastEditors: zjy
 * @LastEditTime: 2024-01-15 19:59:46
 * @Description: 滚动加载更多指令
 * @FilePath: src\utils\loadMore.js
 */
import { debounce } from '../utils/util'
export default {
  bind(el, binding, vnode) {
    const { expand } = binding.modifiers
    // 使用更丰富的功能，支持父组件的指令作用在指定的子组件上
    if (expand) {
      /**
       * target 目标DOM节点的类名
       * distance 减少触发加载的距离阈值，单位为px
       * func 触发的方法
       * delay 防抖时延，单位为ms
       * load-more-disabled 是否禁用无限加载
       */
      let { target, distance = 1, func, delay = 200 } = binding.value
      if (typeof target !== 'string') return
      let targetEl = el.querySelector(target)
      if (!targetEl) {
        return
      }
      binding.handler = debounce(function () {
        const { scrollTop, scrollHeight, clientHeight } = targetEl
        let disabled = el.getAttribute('load-more-disabled')
        disabled = vnode[disabled] || disabled

        if (scrollHeight <= scrollTop + clientHeight + distance) {
          if (disabled) return
          func && func()
        }
      }, delay)
      targetEl.addEventListener('scroll', binding.handler)
    } else {
      binding.handler = debounce(function () {
        const { scrollTop, scrollHeight, clientHeight } = el
        if (scrollHeight === scrollTop + clientHeight) {
          binding.value && binding.value()
        }
      }, 200)
      el.addEventListener('scroll', binding.handler)
    }
  }
}
