import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import website from '@/utils/config-website'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Load from './components/load/index'
import './directives/index'
Vue.use(Load.directive)

import md5 from 'js-md5'
Vue.prototype.$md5 = md5

import SparkMD5 from "spark-md5"
Vue.prototype.$SparkMD5 = SparkMD5

import './assets/css/scrollBarStyle.css'

// if (process.env.NODE_ENV === 'development') {
//   require('./mock/index.js')
// }

// import echarts from 'echarts';

Vue.use(ElementUI)
Vue.config.productionTip = false

// 加载website
Vue.prototype.website = website

// Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
