/*
 * @Description: 
 * @Author: 名字
 * @Date: 2024-01-05 10:49:27
 * @LastEditTime: 2024-01-30 14:54:21
 * @LastEditors: 名字
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import premission from './modules/premission'
import member from './modules/member'
import common from './modules/common'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    premission,
    member,
    common
  },
  getters
});

export default store
