/*
 * @Author: zhengjieyi
 * @Date: 2024-01-06 15:05:48
 * @LastEditors: 名字
 * @LastEditTime: 2024-03-30 16:21:38
 * @Description: 接口请求
 * @FilePath: src\utils\request.js
 */

import axios from 'axios'
import store from '@/store/'
import router from '@/router/index'
import { getStore } from '@/utils/store'
import { Message } from 'element-ui'

export const baseURL ='https://hyxh.szgcsk.cn'
// export const baseURL ='https://mis.link-biz.cn'
//默认超时时间
const service = axios.create({
    baseURL,
    timeout: 60000 // 设置统一的超时时长
})

let custom_options_merge = {}

const instance = (options = {}) => {
    // 自定义配置
    let custom_options = {
        // 是否显示 错误信息
        error_message_show: true,

        // 是否放行400的响应
        greenLight: false
    }

    custom_options_merge = Object.assign(custom_options, options)

    Object.assign(custom_options, options)

    // 请求拦截器
    service.interceptors.request.use(
        (config) => {
            const meta = config.meta || {}
            const isToken = meta.isToken === false
            const token = getStore({ name: 'token' })
            //让每个请求携带token
            if (token && !isToken) {
                config.headers['Authorization'] = token
            }
            //headers中配置text请求
            if (custom_options_merge.text === true) {
                config.headers['Content-Type'] = 'text/plain'
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    // 响应拦截器
    service.interceptors.response.use(
        (res) => {
            if (res) {
                // 如果响应头中包含新的Token
                if (res.headers['Token']) {
                    // 获取新的Token
                    const newToken = res.headers['Token'];
                    // 替换本地存储的Token
                    // localStorage.setItem('token', newToken);
                    store.commit("SET_TOKEN", newToken);
                }
                // 获取状态码
                const status = res.code || res.data.code
                //  获取 后端返回的信息
                const message = res.errMsg || res.data.errMsg || '未知错误';
                const fileId = res.config.fileId;
                //登录
                const loginFlag = res.config.label === 'login' ? true : false
                if (fileId || loginFlag) {
                    //上传图片
                    return res
                } else {

                    if (status === 101) {
                        store.dispatch('LogOut').then(() => router.push({ path: '/login' }))
                    }

                    // 放行为400状态码的响应
                    if (status === 400 && custom_options_merge.greenLight) {
                        return res
                    }

                    // 如果请求为非200否者默认统一处理
                    //code 状态码：0=成功，其他状态码代表异常
                    if (status !== 0 || !res.data) {
                        // ! 建议不要弹出红色提示框
                        Message({
                            message: message,
                            type: 'error'
                        })
                        return Promise.reject({
                          rejectType: 'none',
                          message
                        })
                    }
                    return res
                }
            }
        },
        (error) => {
            return Promise.reject(error)
        }
    )
}

const request = {
    /**
     * get方法
     * @param {*} url     请求url, 相对url
     * @param {*} params  请求参数
     * @param {*} options 扩展配置
     */
    get(url, params, options) {
        return this.sendRequest(url, 'GET', params, options)
    },

    /**
     * post方法
     * @param {*} url     请求url, 相对url
     * @param {*} params  请求参数
     * @param {*} options 扩展配置
     */
    post(url, params, options) {
        return this.sendRequest(url, 'POST', params, options)
    },

    /**
     * post方法
     * @param {*} url     请求url, 相对url
     * @param {*} params  请求参数
     * @param {*} options 扩展配置
     */
    put(url, params, options) {
        return this.sendRequest(url, 'put', params, options)
    },

    /**
     * postArg方法 --  params是添加到url的请求字符串中的
     * @param {*} url     请求url, 相对url
     * @param {*} params  请求参数
     * @param {*} options 扩展配置
     */
    postArg(url, params, options) {
        return this.sendRequest(url, 'POST', params, {
            ...options,
            isParamsType: true
        })
    },
    /**
     *
     * @param {*} url 请求url, 相对url
     * @param {*} method 请求方法
     * @param {*} params 请求参数
     * @param {*} options 扩展配置
     */
    sendRequest(url, method, params, options = {}) {
        instance(options)

        let defaultOptions = {
            url,
            method
        }

        // 全部转大写
        const methodToUpper = method.toUpperCase()
        if (methodToUpper === 'GET') {
            defaultOptions.params = params
        } else {
            defaultOptions[options.isParamsType ? 'params' : 'data'] = params
        }

        options = { ...defaultOptions, ...options }

        return new Promise((resolve, reject) => {
            service
                .request(options)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    console.log(err)
                    if(err.rejectType === 'none') {
                      return
                    }
                    Message({
                      message: err.message,
                      type: 'error'
                  })
                    reject(err)
                })
        })
    }
}
export default request
